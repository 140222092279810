import Animate from 'animation';
import SmoothScroll, { smoothScrollTo } from 'smooth-scroll';
import "slick/slick.min.js";
import "slick/slick.min.css";
import 'fixed-header';
import GoogleMaps from 'locations-map';
import Plyr from 'plyr'; import 'plyr/dist/plyr.css';
import 'dropdown-menu';
import { Cookie } from 'cookie';
import DOMPurify from 'dompurify';

var homeTabPosition = 1;
var initialGhostedVideoPlay = false;
var ghostVideoLength = 1.3;
var ghostVideoInterval = 1.5;

window.addEventListener('error', function () {
    $('body').addClass('loaded');
    $('body').addClass('error');
});

var App = (function ($) {

    function handleCookieConsent() {
        if (!Cookie.read("cookie:consent")) {
            $("#cookie-consent").addClass("active");
        }

        $("#cookie-consent button").click(function () {
            Cookie.write("cookie:consent", "true");
            $("#cookie-consent").removeClass("active");
        })
    };


    function handleSelect() {
        $('.select > select').each(function () {
            if ($(this).find(':selected').get(0).hasAttribute('default')) {
                $(this).parent().addClass('default-selected')
            }
        })

        $('.select > select').on("change", function () {
            if ($(this).find(':selected').get(0).hasAttribute('default')) {
                $(this).parent().addClass('default-selected')
            } else {
                $(this).parent().removeClass('default-selected')
            }
        });
    };

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"), // Case insensitive
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    //For old small banner widget

    //function handlePosition() {
    //    if ($('.right-widget-section-relative').length) {
    //        let window_width = $(window).width()
    //        let new_left = window_width / 11.89
    //        //console.log($('.right-widget-section-relative').position().left)
    //        //console.log($(window).width())
    //        $('.right-widget-section-relative').css({ left: new_left });
    //        //console.log($('.right-widget-section-relative').position().left)
    //    }
    //    else {
    //        return
    //    }
    //} $(window).resize(function () {
    //    if ($('.right-widget-section-relative').length) {
    //        let window_width = $(window).width()
    //        let new_left = window_width / 11.89
    //        console.log($('.right-widget-section-relative').position().left)
    //        //console.log($(window).width())
    //        $('.right-widget-section-relative').css({ left: new_left });
    //        console.log($('.right-widget-section-relative').position().left)
    //    }
    //    else {
    //        return
    //    }
    //})

    function handleFileInput() {
        $('.file-input input[type="file"]').on("change", function () {
            if (this.value) {
                $(this).removeClass("placeholder-shown");
                $(this).siblings('.file-name').html(this.value.replace(/^.*\\/, ""))
            } else {
                $(this).addClass("placeholder-shown");
                $(this).siblings('.file-name').html("Browse...")
            }
        });
    }

    var sliders = [];

    function handleSliders() {
        sliders.forEach(function ($ele) {
            $ele.slick("unslick");
        });

        sliders = [];

        $('.slider').each(function () {
            var options = {
                arrows: false,
                dots: false,
                slidesToShow: 1,
                autoplay: false,
                autoplaySpeed: 5000
            };

            var $this = $(this);

            if ($this.data('slider-height') == "adaptive")
                $.extend(options, { adaptiveHeight: true });

            if ($this.hasClass('slider--dots'))
                $.extend(options, { dots: true });

            if ($this.hasClass('slider--arrows'))
                $.extend(options, {
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span><span class="bubble bubble--left"></span></button>',
                    nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span><span class="bubble"></span></button>'
                });

            if ($this.hasClass('slider--arrows2'))
                $.extend(options, {
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span><span class="bubble bubble--left"></span></button>',
                    nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span><span class="bubble"></span></button>'
                });

            if ($this.hasClass('slider--arrows-block'))
                $.extend(options, {
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span><span class="bubble bubble--left"></span></button>',
                    nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span><span class="bubble"></span></button>'
                });

            if ($this.data('slider-items'))
                $.extend(options, { slidesToShow: $this.data('slider-items') });

            if ($this.data('slider-fade'))
                $.extend(options, { fade: true });

            if ($this.data('slider-center') != null)
                $.extend(options, { centerMode: true, centerPadding: $this.data('slider-center') });

            if ($this.data('slider-pause-on-hover') != null)
                $.extend(options, { pauseOnHover: $this.data('slider-pause-on-hover'), pauseOnFocus: $this.data('slider-pause-on-hover') });

            if ($this.data('slider-asnavfor'))
                $.extend(options, { asNavFor: $this.data('slider-asnavfor') });

            if ($this.data('slider-autoplay') == false) {
                $.extend(options, { autoplay: false });
            } else {
                $.extend(options, { autoplay: true, autoplaySpeed: $this.data('slider-autoplay') });
            }

            if ($this.hasClass("slider--next-work")) {
                $.extend(options, {
                    responsive: [
                        {
                            breakpoint: 998,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1
                            }
                        }]
                });
            }

            if ($this.hasClass("slider--e-sub-1")) {
                $.extend(options, {
                    arrows: true,
                    prevArrow: $('.e-sub-p-1'),
                    nextArrow: $('.e-sub-n-1')
                });
            }

            if ($this.hasClass("slider--e-sub-2")) {
                $.extend(options, {
                    arrows: true,
                    prevArrow: $('.e-sub-p-2'),
                    nextArrow: $('.e-sub-n-2')
                });
            }

            if ($this.hasClass("slider--timeline-cta")) {
                $.extend(options, {
                    //autoplay: false,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                dots: false
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--technologies")) {
                $.extend(options, {
                    autoplay: false
                });
            }

            if ($this.hasClass("slider--technologies-test")) {
                $.extend(options, {
                    //autoplay: false,
                    //customPaging : function(slider, i) {
                    //var thumb = jQuery(slider.$slides[i]).data();
                    //// return '<a>'+(i+1)+'</a>'; // <-- old
                    //return '<a>'+('0'+(i+1)).slice(-2)+'</a>'; // <-- new
                    //},
                    //appendDots: '.custom-dots'
                    dots: true
                });
            }

            if ($this.hasClass("slider--lp")) {
                $.extend(options, {
                    dots: true,
                    fade: true,
                    autoplay: false
                });
            }

            if ($this.hasClass("slider--1")) {
                $.extend(options, {
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    asNavFor: '.slider--2'
                });
            }

            if ($this.hasClass("slider--2")) {
                $.extend(options, {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    customPaging: function customPaging(slider, i) {
                        var thumb = jQuery(slider.$slides[i]).data();

                        return '<a role="button">' + ('0' + (i + 1)).slice(-2) + '</a>';
                    },
                    appendDots: '.custom-dots2',
                    asNavFor: '.slider--1'
                });
            }

            if ($this.hasClass("slider--market")) {
                $.extend(options, {
                    //autoplay: false,
                    dots: true
                });
            }

            if ($this.hasClass("slider--spicer")) {
                $.extend(options, {
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 1560,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 1300,
                            settings: {
                                slidesToShow: 1
                            }
                        }]
                });
            }

            if ($this.hasClass("slider--landing")) {
                $.extend(options, {
                    pauseOnHover: false,
                    pauseOnFocus: false
                });
            }

            if ($this.hasClass("slider--inclusion")) {
                $.extend(options, {
                    //autoplay: false,
                    dots: true,
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: 0,
                    responsive: [
                        {
                            breakpoint: 567,
                            settings: {
                                slidesToShow: 1,
                                centerMode: false
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--product-showcase")) {
                $.extend(options, {
                    autoplay: true,
                    autoplaySpeed: 3000,
                    dots: true,
                    //adaptiveHeight: true
                });
            }

            if ($this.hasClass("slider--related-products")) {
                $.extend(options, {
                    autoplay: false,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 1279,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--awards")) {
                $.extend(options, {
                    //autoplay: false,
                    slidesToShow: 6,
                    responsive: [
                        {
                            breakpoint: 1559,
                            settings: {
                                slidesToShow: 5,
                            }
                        },
                        {
                            breakpoint: 1279,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 567,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--supplier-awards")) {
                $.extend(options, {
                    slidesToShow: 5,
                    responsive: [

                        {
                            breakpoint: 1279,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 780,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--products")) {
                $.extend(options, {
                    autoplay: false,
                    slidesToShow: 4,
                    responsive: [
                        {
                            breakpoint: 1279,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 567,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--brands")) {
                $.extend(options, {
                    autoplaySpeed: 2000
                });
            }

            if ($this.hasClass("slider--other-sectors")) {
                $.extend(options, {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass("slider--annual-report")) {
                $.extend(options, {
                    autoplay: true,
                    fade: true,
                    pauseOnFocus: true,
                    pauseOnHover: true
                });

                setTimeout(function () {
                    $('.slider--annual-report').slick('slickPlay');
                }, 3000);

            }

            if ($this.hasClass("slider--numbers")) {
                $.extend(options, {
                    autoplay: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 567,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });

          }

          if ($this.hasClass("slider--numbers-22")) {
            $.extend(options, {
              autoplay: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              responsive: [
                {
                  breakpoint: 567,
                  settings: {
                    slidesToShow: 1,
                  }
                }
              ]
            });

          }

            if ($this.hasClass("slider--customer")) {
                $.extend(options, {
                    adaptiveHeight: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                adaptiveHeight: false,
                            }
                        }
                    ]
                });

            }

            $this.slick(options);

            sliders.push($this);
        });

        $('.modal').on('shown.bs.modal', function (e) {
            $('.slider--products-modal').slick('setPosition');
            $('.wrap-modal-slider').addClass('open');
        })
    }

    function handleScrollToTop() {
        var $balloon = $('#big-balloon');

        var onScroll = function () {
            if ($(window).scrollTop() >= 800) {
                $balloon.addClass('active');
            } else {
                $balloon.removeClass('active');
            }
        }

        onScroll();

        $(window).scroll(debounce(onScroll, 250));

        var $root = $('html, body');

        $balloon.click(function (e) {
            e.preventDefault();
            smoothScrollTo($root);
        });
    }

    function handleAnimation() {
        Animate.clearElements();

        $('.animate--video').on("animate:in", function () {
            var video = $(this).get(0);
            video.play();
        });

        $('.animate--video').on("animate:out", function () {
            var video = $(this).get(0);
            video.pause();
            video.currentTime = 0;
        });

        $('.animate').scrollAnimate();
    }

    function handleAccordions() {
        var open = function (button, animationTime = 300) {
            var $button = $(button);
            var $content = $($button.data('for'));
            $content.add($button).removeClass('collapsed');

          $content.slideDown(animationTime, function () { });
        }

        var close = function (button) {
            var $button = $(button);
            var $content = $($button.data('for'));

            $content.slideUp(300, function () { $content.add($button).addClass('collapsed'); });
        }

        // Change this to not take this action if an addiional class .accordion-expanded exists
        $('.accordion-content, .accordion-toggle').not('.accordion-expanded').each(function () { $(this).addClass('collapsed'); });
        $('.accordion-content').not('.accordion-expanded').each(function () { $(this).css('display', 'none') });

        // Force .accordion-expanded to start open
        $('.accordion-toggle.accordion-expanded').each(function () {
          open(this, 0);
        });

        $('.accordion-toggle').click(function () {
            if ($(this).hasClass('collapsed')) {
                open(this);
            } else {
                close(this);
            }

            $(this).blur();
        })
    }

    function handleSmoothScroll() {
        $('a[href^="#"]')
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('[data-toggle="tab"]')
            .not('.no-scroll')
            .not(".JumpNav")
            .SmoothScroll();
    }

    function handleAnchorScroll() {
		// Select all links with hashes
		$('a[href*="#"]')
			// Remove links that don't actually link to anything
			.not('[href="#"]')
			.not('[href="#0"]')
			.click(function (event) {
				// On-page links
				if (
					location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
					&&
					location.hostname == this.hostname
				) {
					// Figure out element to scroll to
					var target = $(this.hash);
					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					// Does a scroll target exist?
					if (target.length) {
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						$('html, body').animate({
							scrollTop: target.offset().top
						}, 1000, function () {
							// Callback after animation
							// Must change focus!
							var $target = $(target);
							$target.focus();
							if ($target.is(":focus")) { // Checking if the target was focused
								return false;
							} else {
								$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
								$target.focus(); // Set focus again
							};
						});
					}
				}
			});
    }

    function handleLazyLoad() {
        var $lazy = $('[data-lazy]');

        $lazy.on("animate:in", function () {
            $(this).attr('src', $(this).data('lazy'))
        });

        $lazy.scrollAnimate({ displacement: -2000 });
    }

    function handleHomepageVideo() {
        var video = $("#videoHome").get(0);
        var $play = $('#playVideo');
        var $banner = $('.banner--hero');

        if (video) {
            const player = new Plyr(video, {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen']
            });

            $play.on('click', function (e) {
                player.play();
            });

            player.on('play', function () {
                $banner.removeClass('paused').addClass('playing');
            });

            player.on('pause ended', function () {
                if (!player.seeking) {
                    $banner.addClass('paused').removeClass('playing');
                    player.fullscreen.exit();
                }
            });
        }
    }

    function handleHeader() {
        $("#header").fixedHeader();

        //$('.sf-menu').superfish();

        $('#header .navigation > ul').dropdown();
        $('.mega-menu').dropdown({ delay: 150 });

        $('#nav-icon1').click(function () {
            $(this).toggleClass('open');
            $('body').toggleClass('open');
        });

        var $toggle = $('.search-column .search');

        $('body').click(function (e) {
            var target = $(e.target);

            if ($(e.target).closest("#search-bar").length > 0 || $(e.target).closest(".search-column .search").length) {
                return false;
            }

            $("#search-bar").removeClass('active');
        });

        $toggle.click(function () {
            $("#search-toggle").toggleClass('active');
            $("#search-bar").toggleClass('active');
            $("#search-bar input").focus();
        });

        $('.sub-menu').hide()

        if ($('li.active')) {
            $('li.active').children('.sub-menu').show();
        }

        $(".toggle-dropdown").click(function () {
            $(this).parent().parent().children("ul").slideToggle(100);
            $(this).toggleClass("fa-angle-up fa-angle-down");
        });
    }

    function handleIndiaCode() {
        $('.mobility-tab-section .card-header').click(function () {
            $('.card-header').removeClass('active');
            $(this).addClass('active');
        });

        $('#careerVideoBtn').click(function () {
            $('.careers-section').addClass('hidden');
            $('.play-button').fadeOut();
            setTimeout(function () {
                document.getElementById('careerVideo').play();
            }, 1000);
        });

        $(".list-unstyled").on("click", ".init", function () {
            $(this).closest("ul").children('li:not(.init)').toggle();
        });

        var allOptions = $(".list-unstyled").children('li:not(.init)');

        $(".list-unstyled").on("click", "li:not(.init)", function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $(".list-unstyled").children('.init').html($(this).html());
            allOptions.toggle();
        });

        $('.item1').click(function () {
            $('.technology-section a.one').trigger('click');
        });

        $('.item2').click(function () {
            $('.technology-section a.two').trigger('click');
        });

        $('.item3').click(function () {
            $('.technology-section a.three').trigger('click');
        });

        $('.item4').click(function () {
            $('.technology-section a.four').trigger('click');
        });

        $('.item5').click(function () {
            $('.technology-section a.five').trigger('click');
        });

        $('.item6').click(function () {
            $('.technology-section a.six').trigger('click');
        });

        $('.item7').click(function () {
            $('.technology-section a.seven').trigger('click');
        });
    }

    function bindLocationDropdownChange() {
        if ($("#filteredLocations").length) {

            $("#Region,#Country,#BusinessUnit,#FacilityType").change(function () {
                updateLocationContent();
            });
        }

    }


    function updateLocationContent() {


        var Region = $('#Region').val();
        var Country = $('#Country').val();
        var Market = $('#BusinessUnit').val();
        var FacilityType = $('#FacilityType').val();
        var LocationContainerPageID = $('#LocationContainerPageID').val();


        var formvals = {
            Region: Region,
            Country: Country,
            BusinessUnit: Market,
            FacilityType: FacilityType,
            LocationContainerPageID: LocationContainerPageID
        };


        $.ajax({
            type: "POST",
          url: "/LocationsAccordionAjax/FilterLocationsGmap",
          data: $.param(formvals),
          contentType: "application/x-www-form-urlencoded",
            success: function (result, status, xhr) {
                $("#block-78b3eb90-e90c-4fbe-a68b-b88aa0b3ab9d").html(result);
                bindLocationDropdownChange();
                GoogleMaps();
            },
            error: function (xhr, status, error) {

            }
        });

    }


    function bindLocationAccordionChange() {
        if ($("#filteredLocations").length) {

            $("#BusinessUnit_Listing,#FacilityType_Listing").change(function () {
                updateLocationAccordionContent();
                //App.main();
            });
        }
    }


    function updateLocationAccordionContent() {

        var Market = $('#BusinessUnit_Listing').val();
        var FacilityType = $('#FacilityType_Listing').val();
        var LocationContainerPageID = $('#LocationContainerPageID_Listing').val();


        var formvals = {
            Region: '',
            Country: '',
            BusinessUnit: Market,
            FacilityType: FacilityType,
            LocationContainerPageID: LocationContainerPageID
        };


        $.ajax({
            type: "POST",
            url: "/LocationsAccordionAjax/FilterLocations",
            data: $.param(formvals),
            contentType: "application/x-www-form-urlencoded",
            success: function (result, status, xhr) {
                $("#block-ce693e25-f74d-4435-bbce-99190e758657").html(result);

                bindLocationAccordionChange();
                handleAccordions();
            },
            error: function (xhr, status, error) {

            }
        });

        //var elem = $(".accordion-toggle");
        //var opa = 0;
        //var id = setInterval(frame, 5);
        //elem.style.opacity = opa
        //function frame() {
        //    if (opa == 1) {
        //        clearInterval(id);
        //    } else {
        //        opa += 0.01;
        //        elem.style.opacity = opa
        //    }
        //}

    }

    var ghostedVideoPlayInterval;
    var currentGhostVideo = 1;

    function checkDanaHomeVideoScroll() {

        if (!initialGhostedVideoPlay && $("#DANAVP1").is(":in-viewport")) {
            playGhostVideo(1);
            initialGhostedVideoPlay = true;
        };
    }

    var pausing_function = function (e, stopTime) {
        if (e.currentTime >= stopTime) {
            e.pause();
            //console.log('Video PAUSE ' + e.currentTime); 

            e.currentTime = stopTime;


            // remove the event listener after you paused the playback
            clearInterval(ghostedVideoPlayInterval);
        }
    };

    var playGhostVideo = function (vidNumber) {

        // In case new video is requested before old one finishes
        clearInterval(ghostedVideoPlayInterval);

        var theVideo = $("#DANAVP1")[0];

        theVideo.currentTime = ((vidNumber - 1) * ghostVideoInterval + 0.1);  // Add 0.1 padding to assist IE seek issues

        currentGhostVideo = vidNumber;
        theVideo.play();

        console.log('Video PLAY ' + vidNumber + ' ' + theVideo.currentTime);
        ghostedVideoPlayInterval = setInterval(function () {
            pausing_function(theVideo, (((vidNumber - 1) * ghostVideoInterval) + ghostVideoLength))
        }, 20);
    };


    function handleDANAHomeVideo() {

        //$(testDisplayDiv).click(function (e) {
        //  clearInterval(testDisplay);
        //});

        $(window).scroll(function () {
            if (!initialGhostedVideoPlay) {
                checkDanaHomeVideoScroll();
            };
        });
        checkDanaHomeVideoScroll(); // On load

        $("#DANAVPT1").click(function () {
            if (homeTabPosition != 1) {
                playGhostVideo(homeTabPosition == 2 ? 4 : 6);
            }

            homeTabPosition = 1;
        });


        $("#DANAVPT2").click(function () {
            if (homeTabPosition != 2) {
                playGhostVideo(homeTabPosition == 1 ? 2 : 7);
            }

            homeTabPosition = 2;
        });

        $("#DANAVPT3").click(function () {

            if (homeTabPosition != 3) {
                playGhostVideo(homeTabPosition == 1 ? 3 : 5);
            }

            homeTabPosition = 3;
        });
    }

    function handleSideNav() {
        if ($('.sub-menu').hasClass('.active')) {
            console.log('true')
        };

        $('.side-menu_expand').click(function () {
            $(this).siblings('ul').toggleClass('active');
            $(this).toggleClass('active');
        });
    }

    function handleTabsList() {
        $('#tabSelect').on('change', function (e) {
            $('#myTab li a').eq($(this).val()).tab('show');
        });
        $('#tabSelectapp').on('change', function (e) {
            $('#appTab li a').eq($(this).val()).tab('show');
        });
    }

    function handleTabMulti() {
        $('.app-tab-wrap #appTab a').click(function () {
            var href = $(this).attr('href');
            var num = href.split("-")[1];
            $('.tab-pane-test').css('display', 'none');
            $("[class^='etab-'][class^='etabi-'][class^='etabt-']").removeClass('show');
            $(".etab-p-" + num).css('display', 'block');
            $(".etabi-img-" + num).css('display', 'block');
            $(".etabt-test-" + num).css('display', 'block');
            $(".etab-p-" + num).addClass('show');
            $(".etabi-img-" + num).addClass('show');
            $(".etabt-test-" + num).addClass('show');
        });
    }

    function handlePopovers() {
        $('[data-toggle="popover"]').popover({
            html: true,
            content: function () {
                return $(this).next('.popover-content').html();
            },
            sanitizeFn: function (content) {
                return DOMPurify.sanitize(content)
            }
        });
        $(document).on("click", ".popover .close-popover", function () {
            $(this).parents(".popover").popover('hide');
        });
        $(document).on("click", ".applications-filter .nav-tabs--applications li a", function () {
            $('.popover').popover('hide');
        });
        $(document).on("click", ".nav-tabs--applications-sub li a", function () {
            $('.popover').popover('hide');
        });
        $(document).on("click", ".ghosted-vehicle-toggle", function () {
            $('.popover').popover('hide');
        });
        $(document).on("change", "#tabSelect", function () {
            $('.popover').popover('hide');
        });
        $(document).on('show.bs.popover', function () {
            $('.popover').not(this).popover('hide');
        });
    }

    function handleRepTool() {

        $('#rep-popout').find(".text-white.bg-blue").click(function () {
            if ($('#rep-popout').attr('data-tab') == 'closed') {
                $('#rep-popout').attr('data-tab', 'open')
            }
            else {
                $('#rep-popout').attr('data-tab', 'closed')
            }
        });

        $('#rep-popout').find("div.tab-btn").click(function () {
            $('#rep-popout').attr('data-tab', 'closed')
        });

        $('#rep-popout').find("div.close-tab").click(function () {
            $('#rep-popout').attr('data-tab', 'hidden')
        });

        $("#findrep-countries").change(function () {

            var stateSelect = $("#findrep-states");

            // Reset disabled elements, will be enabled if conditions met
            if (!$(stateSelect).is("[disabled]")) {
                $(stateSelect).attr('disabled', 'disabled');
                $(stateSelect).parent().prev('label').css('color', '#8b8b8b');
            };

            if (!$("#rep-popout .submit-btn").is("[disabled]")) {
                $("#rep-popout .submit-btn").attr('disabled', 'disabled');
            };


            if ($("#findrep-countries").val() != '') {

                $.ajax({
                    type: "POST",
                    url: "/FindMyRepBlock/FilterDropdown",
                    data: JSON.stringify({
                        Country: $("#findrep-countries").val(), Repcontainer: $("#findrep-countries").data("repcontainer")
                    }),
                    contentType: "application/json",
                    success: function (result, status, xhr) {



                        // Clear state list and populate initial option
                        $(stateSelect).find('option').remove().end().append('<option value="">Select One</option>');

                        $.each(result, function (key, value) {
                            $(stateSelect).append('<option value="' + key + '">' + value + '</option>');
                        });


                        $(stateSelect).removeAttr('disabled');
                        $(stateSelect).parent().prev('label').css('color', 'inherit');
                        $("#rep-popout .submit-btn").removeAttr('disabled');

                    },
                    error: function (xhr, status, error) {

                    }
                });

            };


        });


        $("#rep-popout .submit-btn").click(function (e) {
            e.preventDefault();

            $.ajax({
                type: "POST",
                url: "/FindMyRepBlock/GetRep",
                data: JSON.stringify({
                    Country: $("#findrep-countries").val(), State: $("#findrep-states").val(), Repcontainer: $("#findrep-countries").data("repcontainer")
                }),
                contentType: "application/json",
                success: function (result, status, xhr) {

                    console.log(result);

                    if (result != null && result.length > 0) {

                        $("#findrep-modal-state").html(result[0].State);
                        $("#findrep-modal-name").html(result[0].Name);
                        $("#findrep-modal-phone").html('<a href="tel:' + result[0].Phone + '">' + result[0].Phone + '</a>');
                        $("#findrep-modal-email").html('<a href="mailto:' + result[0].Email + '">' + result[0].Email + '</a>');

                        $('.rep-modal').modal();

                    };

                },
                error: function (xhr, status, error) {

                }
            });

            return false;
        });
    }

    function handleContactForm() {
        var formObj = $('form#757b0e78-c21b-455a-8dcd-de748f95bd47');
        if ($(formObj).length) {
            var fieldObj = $('select#865eb447-39ce-4721-9330-b0bf312395bc');
            var qs = getParameterByName("market");
            if (qs == "lv") {
                $(fieldObj).val('Light Vehicle');
            } else if (qs == "cv") {
                $(fieldObj).val('Commercial Vehicle');
            } else if (qs == "oh") {
                $(fieldObj).val('Off-Highway and Industrial');
            } else if (qs == "gen") {
                $(fieldObj).val('General');
            };
        };
    };

    function handleGatedForm() {
        var formObj = $('form#904e1e1a-22cf-46d7-8e82-7760a9573d48');
        if ($(formObj).length) {
            // gatedcontenttileblock-data
            var blockId = $(formObj).prev(".gatedcontenttileblock-data").data("cid");

            // Set the value of the final hidden input in the form to blockId
            $(formObj).find(".FormHidden:last").val(blockId);

        };
  };

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  function handleHeaderSearch() {

    var searchForm = document.getElementById("header-search-form");

    if (searchForm) {
      searchForm.addEventListener("submit", function (event) {
        if (inIframe()) {
          event.preventDefault();
        }
      });
    }

  };

    function handleHashLinkScrollingOnLoad() {
        if (window.location.hash) {
            var hash = window.location.hash;

            if ($(hash).length) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - 80
                }, 500, 'swing');
            }
        }
    };

    function handleCountUp() {
        var countingNumber = $(".countUp");
        var countUpItems = [];
        var countUpCount = 0;


        countingNumber.each(function () {
            var fromNumber = 0;
            var toNumber = $(this).text();
            if ($(this).data("from") != null) {
                fromNumber = $(this).data("from");
            }

            var decimals = 0;
            if ($(this).data("decimals") != null) {
                decimals = $(this).data("decimals");
            }

            var duration = 3;
            if ($(this).data("duration") != null) {
                duration = $(this).data("duration");
            }
            var sep = "";
            if ($(this).data("seperator") != null) {
                sep = $(this).data("seperator");
            }

            var thisID = $(this).attr("id")
            var options = {
                useEasing: true,
                useGrouping: true,
                separator: sep,
                decimal: '.',
                prefix: '',
                suffix: ''
            };

            countUpItems[countUpCount] = new CountUp(thisID, fromNumber, toNumber, decimals, duration, options);

            ++countUpCount;

        });

        var animationFunction = function () {
            var cnt = 0;
            var countingNumber = $(".countUp");

            countingNumber.each(function () {
                var thisID = $(this).attr("id")

                if ($('#' + thisID).isOnScreen()) {
                    var thisCount = cnt;
                    countUpItems[thisCount].start();
                }
                ++cnt;
            });

            requestAnimFrame(animationFunction);
        }


        animationFunction();
    }



    return {
        main: function () {
            handleAnimation();
            handleLazyLoad();
            handleSliders();
            handleSelect();
            handleFileInput();
            handleIndiaCode();
            handleHomepageVideo();
            //handlePosition();
            handleAccordions();
            handleCookieConsent();
            handleSideNav();
            handleTabsList();
            handleTabMulti();
            handlePopovers();
            handleRepTool();
            handleContactForm();
            handleGatedForm();
        handleAnchorScroll();
        handleHeaderSearch();

            try {
                handleDANAHomeVideo();
            } catch (e) { }

            bindLocationDropdownChange();
            bindLocationAccordionChange();

            GoogleMaps();
            handleHeader();
            handleCountUp();

            handleHashLinkScrollingOnLoad();

            $('body').addClass('loaded');
        },
    };
}(jQuery));

$(document).ready(function () {
    App.main();
});
