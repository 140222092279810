var GoogleMapsLoader = require('google-maps'); // https://www.npmjs.com/package/google-maps
GoogleMapsLoader.KEY = 'AIzaSyBB5tCxSMJeydHx-w5X7y1ZWpxooxIMzos';
GoogleMapsLoader.VERSION = '3.38';

var GoogleMaps = (function () {
  function GoogleMaps() {
    var map_el = document.getElementById('map');
    var data_el = document.getElementById('map-data');
 
    if ($(map_el).length) {

      GoogleMapsLoader.load(function (google) {
          var map = new google.maps.Map(map_el, {
            //center doesn't work
          center: new google.maps.LatLng(0,0),
          zoom: 3.5,
          minZoom: 2.2,
          maxZoom: 7,
          disableDefaultUI: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [{ "featureType": "administrative", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "visibility": "off" }] },
          { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "visibility": "off" }] },
          { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#b2b2b2" }] },
          { "featureType": "landscape", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] },
          { "featureType": "landscape", "elementType": "labels.text.fill", "stylers": [{ "visibility": "off" }] },
          { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] },
          { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
          { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] },
          { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#8B8B8B" }] },
          { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#323e48" }, { "visibility": "simplified" }] }]
        });
        var bounds1 = new google.maps.LatLngBounds();
        var mapData = JSON.parse(data_el.innerHTML);

        var infowindow = new google.maps.InfoWindow({
          content: ''
        });

        function bindInfoWindow(marker, map, infowindow, html) {
          google.maps.event.addListener(marker, 'click', function () {
            infowindow.setContent(html);
            infowindow.open(map, marker);
          });
        }

        mapData.forEach(function (datum) {

          bounds1.extend(new google.maps.LatLng(datum.position.lat, datum.position.lng));

          var marker = new google.maps.Marker({
            position: datum.position,
            map: map,
            title: datum.title,
            icon: "/static/img/dana-map-pin.png"
          });
          var thisContent = '';
          if (typeof datum.content.phone === 'undefined') {
             thisContent = '<article class="map-info-window">' +
              '<div class="fac-title">' +
              datum.content.title +
              '</div>' +
              '<div class="fac-address">' +
              datum.content.address + '</div>'
              + '</article>';
          } else {
             thisContent = '<article class="map-info-window">' +
              '<div class="fac-title">' +
              datum.content.title +
              '</div>' +
              '<div class="fac-address">' +
              datum.content.address + '</div>'
              + '<div class="fac-address">' + datum.content.phone + '</div>'
              + '</article>';
          }

          bindInfoWindow(marker, map, infowindow, thisContent);
        });

        map.fitBounds(bounds1);
        //map.setCenter(center);
      });


    };
    
  }
  return GoogleMaps;
}());

export default GoogleMaps;
