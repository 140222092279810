import './_dropdown-menu.scss';

function factory($) {
  function Dropdown($list, options = { delay: 0 }) {
    var hoverTimer;
    var delayTimer;
    var active = false;
    $list.addClass('moncur-dropdown');
    var $NavItem = $list.children('li');
    $NavItem.children('ul, div').addClass('dropdown-submenu')
    var $NavLinks = $NavItem.children('a');

    $NavItem.hover(function () {
      var _ = this;
      clearTimeout(hoverTimer);

      if (!$(_).hasClass('dropdown-active') && !$(_).hasClass('dropdown-pending')) {
        $(_).addClass('dropdown-pending');
        $(_).siblings('li').removeClass('dropdown-pending');

        delayTimer = setTimeout(function () {
          $(_).siblings('li').removeClass('dropdown-open').removeClass('dropdown-pending');
          $(_).addClass('dropdown-open').removeClass('dropdown-pending');
          active = true;
        }, active ? options.delay : 0);
      }
    }, function () {
      var _ = $(this);
      clearTimeout(hoverTimer);
      clearTimeout(delayTimer);
      hoverTimer = setTimeout(function () {
        _.removeClass('dropdown-open').removeClass('dropdown-pending');
        active = false;
      }, 500);
    });

    $(window).on('click', function (e) {
      if (!$(e.target).is($NavItem) && !$(e.target).closest($NavItem).length)
        $NavItem.removeClass('dropdown-open');
    });

    $NavLinks.focus(function () {
      $(this).parent().addClass('dropdown-open');
      $(this).parent().siblings().removeClass('dropdown-open');
    });

    $NavLinks.on('touchstart', function (e) {
      var dropdown = $(this).siblings('.dropdown-submenu');
      if (dropdown.length) {
        e.preventDefault();
        $(this).parent().toggleClass('dropdown-open');
        $(this).parent().siblings().removeClass('dropdown-open');
      }
    });
  }

  Dropdown.makeJQueryPlugin = function () {
    if (!$)
      return;

    $.fn.dropdown = function (options = { delay: 0 }) {
      return new Dropdown($(this), options);
    };
  };

  // try making plugin
  Dropdown.makeJQueryPlugin();

  return Dropdown;
};

export default factory(jQuery);
